var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DatatablePageLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionButtons",
          fn: function() {
            return [
              _c(
                "vs-button",
                {
                  staticClass:
                    "xs:w-full sm:w-auto rounded-lg mb-4 xs:mr-4 sm:mr-4 md:mr-0",
                  attrs: {
                    to: { name: _vm.exportRouteName, params: { type: "csv" } }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("apps.photos.exportcsv")) + " ")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "xs:w-full sm:w-auto rounded-lg",
                  attrs: {
                    to: { name: _vm.exportRouteName, params: { type: "zip" } }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("apps.photos.exportzip")) + " ")]
              )
            ]
          },
          proxy: true
        },
        {
          key: "datatable",
          fn: function() {
            return [
              _c("Datatable", {
                attrs: {
                  config: _vm.datatableConfig,
                  elements: _vm.photos,
                  stripe: true,
                  draggable: false,
                  sst: true,
                  pagination: _vm.pagination
                },
                on: { action: _vm.handleAction, filter: _vm.handleFilter }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "modalPhoto" },
        [
          _c(
            "vs-popup",
            {
              attrs: {
                active: _vm.modalOpenPhoto,
                title: "",
                classContent: "modalPhoto"
              },
              on: {
                "update:active": function($event) {
                  _vm.modalOpenPhoto = $event
                }
              }
            },
            [
              _c("div", { staticClass: "containerPopUp" }, [
                _c("div", { staticClass: "imagePopUp" }, [
                  _c("img", { attrs: { src: _vm.selectedPhoto } })
                ]),
                _c("div", [
                  _c("div", { staticClass: "flex justify-center" }, [
                    _c(
                      "div",
                      [
                        _c("vs-pagination", {
                          staticClass: "mt-2",
                          attrs: { total: _vm.totalPhotos },
                          model: {
                            value: _vm.currentPhotoIndex,
                            callback: function($$v) {
                              _vm.currentPhotoIndex = $$v
                            },
                            expression: "currentPhotoIndex"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }