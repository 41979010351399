export default {
    parseParameters: (params) => {
        try {
            let paramsRequest = {}
            const query = []

            for (const filter of params.filters) {
                let fieldValue = `${filter.field}:${filter.value}`
                query.push(fieldValue)
            }

            if (query.length) {
                paramsRequest.q = query.join(',')
            }

            if (params.page) {
                paramsRequest.page = params.page
            }

            if (params.per_page) {
                paramsRequest.per_page = params.per_page
            }

            if (params.sort_type) {
                paramsRequest.sort_type = params.sort_type
            }

            if (params.sort_by) {
                paramsRequest.sort_by = params.sort_by
            }

            return paramsRequest
        } catch (error) {
            throw new Error(error)
        }
    }
}
